import config from '@/config/env-constants';

// Util
import { SortUtil } from '@/utils/sortUtil';
import { LocationUtil } from '@/utils/locationUtil';

// Others
import _ from 'lodash';


function getCompanyItem(company) {
	return {
		id: company.id,
		name: company.name,
		isActive: company.isActive,
		type: company.type,
		brand: company.brand,
		contactNo: company.contactNo
	}
}

function getStorageLocationItem(loc) {
	return {
		id: loc.id,
		name: loc.name,
		geoaddress: LocationUtil.getGeoaddress(
			loc.geoaddress
		),
		companyId: loc.companyId
	}
}

function getDriverItem(driver) {
	return {
		id: driver.userId,
		name: driver.name,
		company: driver.company,
		companyId: driver.companyId,
		assistants: '',
		isAccountable: driver.isAccountable,
		license: getMedia(driver.license)
	}
}

function getTransportationItem(transportation) {
	return {
		id: transportation.plateNo,
		name: transportation.plateNo,
		company: transportation.company,
		companyId: transportation.companyId,
		hasCheckingInterval: transportation.hasCheckingInterval,
		checkingInterval: transportation.checkingInterval,
	}
}

function getMedia(media) {
	return {
		name: media.name,
		url: media.url,
		latitude: media.latitude,
		longitude: media.longitude
	}
}

function getUserItem(user) {
	return {
		id: user.id,
		name: name,
		companyId: user.companyId,
		type: user.type
	}
}

function getCompanyAccessItem(company) {
	return {
		id: company.id,
		name: company.name,
		description: company.description
	}
}


export const DropDownItemsUtil = {

	// individual option item retrieval
	getCompanyItem,
	getStorageLocationItem,
	getDriverItem,
	getTransportationItem,
	getUserItem,
	getCompanyAccessItem,

	retrieveIndustryItems: (industries, hasOthers) => {
		let industryItems = [];

		// init value
		industryItems.push({
			value: null,
			text: ' - Please select - ',
		});

		_.forEach(industries, industry => {
			industryItems.push({
				value: industry.id,
				text: industry.name,
			});
		});

		// sort options by text
		industryItems = SortUtil.sortByAttr('text', industryItems);
		industryItems = _.uniqBy(industryItems, 'text');

		if (hasOthers) {
			// Others
			industryItems.push({
				value: 'Others',
				text: ' - Others - ',
			});
		}

		return industryItems;
	},

	retrieveCompanyItems: (companies, currCompany) => {
		let companyItems = [{
			value: config.companyDefaultValue,
			text: ' - Please select - ',
		}];

		let parentCompanyId = currCompany ? currCompany.parentCompanyId : '';
		_.forEach(companies, company => {
			if (company.id !== parentCompanyId) {
				companyItems.push({
					value: {
						id: company.id,
						name: company.name,
						isActive: company.isActive,
						type: company.type,
						brand: company.brand,
						contactNo: company.contactNo
					},
					text: company.name
				});
			}
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveAllCompanyAccess: companies => {
		let companyItems = [];

		_.forEach(companies, company => {
			companyItems.push({
				value: {
					id: company.id,
					name: company.name,
					description: company.description
				},
				text: company.name + ' - ' + company.description
			});
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveCompanyAssetOwnerItems: companies => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			if ((company.type === 'Asset Service Provider' ||
				company.type === 'Hybrid') && company.brand !== '') {
				companyItems.push({
					value: getCompanyItem(company),
					text: company.name
				});
			}
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveActiveCompanyItems: (companies) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			if (company.isActive === 'true') {
				companyItems.push({
					value: {
						id: company.id,
						name: company.name,
						isActive: company.isActive,
						type: company.type,
						brand: company.brand,
						contactNo: company.contactNo
					},
					text: company.name
				});
			}
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveConnectedCompanyItems: (connections, currCompany) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		// include the company the current user belongs to
		let currCompanyOption = {
			value: {
				id: currCompany.id,
				name: currCompany.name,
				isActive: currCompany.isActive,
				type: currCompany.type,
				brand: currCompany.brand,
				contactNo: currCompany.contactNo
			},
			text: currCompany.name
		};
		companyItems.push(currCompanyOption);

		// include other connection companies
		_.forEach(connections, connection => {
			if (connection.companyId === currCompany.id
				&& connection.isActive === 'true') {
				let conCompanyOption = {
					value: {
						id: connection.connectedCompanyId,
						name: connection.connectedCompany.name,
						isActive: connection.connectedCompany.isActive,
						type: connection.connectedCompany.type,
						brand: connection.connectedCompany.brand,
						contactNo: connection.connectedCompany.contactNo
					},
					text: connection.connectedCompany.name
				};

				let companyNamesArr = _.map(companyItems, 'text');
				if (!_.includes(companyNamesArr, connection.connectedCompany.name)) {
					companyItems.push(conCompanyOption);
				}
			}
		});

		// sort options by text
		companyItems = SortUtil.sortByAttr('text', companyItems);
		companyItems = _.uniqBy(companyItems, 'text');

		return companyItems;
	},

	retrieveStorageLocationItems: storageLocations => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			storageLocationItems.push({
				value: {
					id: loc.id,
					name: loc.name,
					geoaddress: LocationUtil.getGeoaddress(
						loc.geoaddress
					),
					companyId: loc.companyId
				},
				text: loc.name
			});
		});

		// sort options by text
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);
		storageLocationItems = _.uniqBy(storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveStorageLocationOfConnectionItems: (connections, storageLocations, companyId, connectedCompanyId) => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		if (companyId === connectedCompanyId) {
			// retrieve from list of storage locations
			_.forEach(storageLocations, loc => {
				if (loc.companyId === companyId && loc.isActive === 'true') {
					storageLocationItems.push({
						value: getStorageLocationItem(loc),
						text: loc.name
					});
				}
			});

		} else {
			// retrieve from list of connections
			_.forEach(connections, connection => {
				if (connection.companyId === companyId
					&& connection.connectedCompanyId === connectedCompanyId
					&& connection.isActive === 'true') {
					_.forEach(connection.storageLocations, loc => {
						if (loc.isIncluded === 'true') {
							storageLocationItems.push({
								value: {
									id: loc.id,
									name: loc.name,
									geoaddress: LocationUtil.getGeoaddress(
										loc.geoaddress
									),
									companyId: connection.connectedCompanyId
								},
								text: loc.name
							});
						}
					});
				}
			});
		}

		// sort options by text
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);
		storageLocationItems = _.uniqBy(storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveStorageLocationOfCurrCompany: (currCompanyId, storageLocations) => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			if (loc.companyId === currCompanyId && loc.isActive === 'true') {
				storageLocationItems.push({
					value: {
						id: loc.id,
						name: loc.name,
						geoaddress: LocationUtil.getGeoaddress(
							loc.geoaddress
						),
						companyId: loc.companyId
					},
					text: loc.name
				});
			}
		});

		// sort options by text
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);
		storageLocationItems = _.uniqBy(storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveAssetTypeItems: assetTypes => {
		let assetTypeItems = [];
		assetTypeItems.push({
			value: config.assetTypeDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(assetTypes, type => {
			assetTypeItems.push({
				value: {
					id: type.id,
					name: type.name,
					origin: type.origin,
					originId: type.originId
				},
				text: type.name
			});
		});

		// sort options by text
		assetTypeItems = SortUtil.sortByAttr('text', assetTypeItems);
		assetTypeItems = _.uniqBy(assetTypeItems, 'text');

		return assetTypeItems;
	},

	retrieveActiveDriverItems: (users, hasOthers = true) => {
		let userItems = [];

		userItems.push({
			value: config.driverDefaultValue,
			text: ' - Please select - '
		});

		if (hasOthers) {
			userItems.push({
				value: config.driverOtherValue,
				text: ' - Others - '
			});
		}

		_.forEach(users, user => {
			if (user.isActive === 'true') {
				let name = user.firstName + ' ' + user.lastName;
				userItems.push({
					value: {
						id: user.id,
						name: name,
						company: user.company,
						companyId: user.companyId,
						assistants: '',
						isAccountable: false,
						license: getMedia(user.driversLicense.image)
					},
					text: name
				});
			}
		});

		// sort options by text
		userItems = SortUtil.sortByAttr('text', userItems);
		userItems = _.uniqBy(userItems, 'text');

		return userItems;
	},

	retrieveActiveDriverItemsByCompany: (users, currCompany) => {
		let userItems = [];

		userItems.push({
			value: config.driverDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(users, user => {
			if (user.isActive === 'true' && user.companyId === currCompany.id) {
				let name = user.firstName + ' ' + user.lastName;
				userItems.push({
					value: {
						id: user.id,
						name: name,
						company: user.company,
						companyId: user.companyId,
						assistants: '',
						isAccountable: false,
						license: getMedia(user.driversLicense.image)
					},
					text: name
				});
			}
		});

		// sort options by text
		userItems = SortUtil.sortByAttr('text', userItems);
		userItems = _.uniqBy(userItems, 'text');

		return userItems;
	},

	retrieveActiveUserItems: users => {
		let userItems = [];

		userItems.push({
			value: config.userDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(users, user => {
			if (user.isActive === 'true') {
				let name = user.firstName + ' ' + user.lastName;
				userItems.push({
					value: {
						id: user.id,
						name: name,
						companyId: user.companyId,
						type: user.type
					},
					text: name
				});
			}
		});

		// sort options by text
		userItems = SortUtil.sortByAttr('text', userItems);
		userItems = _.uniqBy(userItems, 'text');

		return userItems;
	},

	retrieveActiveUserItemsByCompanyId: (users, companyId) => {
		let userItems = [];

		userItems.push({
			value: config.userDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(users, user => {
			if (user.isActive === 'true' && user.companyId === companyId) {
				let name = user.firstName + ' ' + user.lastName;
				userItems.push({
					value: {
						id: user.id,
						name: name,
						companyId: user.companyId,
						type: user.type
					},
					text: name
				});
			}
		});

		// sort options by text
		userItems = SortUtil.sortByAttr('text', userItems);
		userItems = _.uniqBy(userItems, 'text');

		return userItems;
	},

	retrieveTransportationItems: (transportations, hasOthers = true) => {
		let transportationItems = [];

		transportationItems.push({
			value: config.transportationDefaultValue,
			text: ' - Please select - '
		});

		if (hasOthers) {
			transportationItems.push({
				value: config.transportationOthersValue,
				text: ' - Others - '
			});
		}

		_.forEach(transportations, transportation => {
			if (transportation.isActive === 'true') {
				transportationItems.push({
					value: {
						id: transportation.plateNo,
						name: transportation.plateNo,
						company: transportation.company,
						companyId: transportation.companyId,
						hasCheckingInterval: transportation.hasCheckingInterval ? transportation.hasCheckingInterval : false,
						checkingInterval: transportation.checkingInterval ? transportation.checkingInterval : 0
					},
					text: transportation.plateNo
				});
			}
		});

		// sort options by text
		transportationItems = SortUtil.sortByAttr('text', transportationItems);
		transportationItems = _.uniqBy(transportationItems, 'text');

		return transportationItems;
	},

	retrievePlateNoItems: transportations => {
		let plateNoItems = [];

		plateNoItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(transportations, transportation => {
			if (transportation.isActive === 'true') {
				plateNoItems.push({
					value: transportation.plateNo,
					text: transportation.plateNo
				});
			}
		});

		// sort options by text
		plateNoItems = SortUtil.sortByAttr('text', plateNoItems);
		plateNoItems = _.uniqBy(plateNoItems, 'text');

		return plateNoItems;
	},

	retrieveAssetCategoryItems: (categories) => {
		let assetCategoryItems = [];

		assetCategoryItems.push({
			value: null,
			text: ' - Please select - ',
		});

		_.forEach(categories, category => {
			assetCategoryItems.push({
				value: category.name,
				text: category.name,
			});
		});

		// sort options by text
		assetCategoryItems = SortUtil.sortByAttr('text', assetCategoryItems);
		assetCategoryItems = _.unionBy(assetCategoryItems, 'text');

		return assetCategoryItems;
	},

	retriveUOMItems: (uoms) => {
		let uomItems = [];

		uomItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(uoms, uom => {
			uomItems.push({
				value: uom.id,
				text: uom.code + ' (' + uom.name + ')',
			});
		});

		// sort options by text
		uomItems = SortUtil.sortByAttr('text', uomItems);
		uomItems = _.unionBy(uomItems, 'text');

		return uomItems;
	},

	retriveIncidentTypeItems: (incidentTypes) => {
		let incidentTypeItems = [];

		incidentTypeItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(incidentTypes, incidentType => {
			incidentTypeItems.push({
				value: {
					id: incidentType.id,
					name: incidentType.name,
				},
				text: incidentType.name,
			});
		});

		// sort options by text
		incidentTypeItems = SortUtil.sortByAttr('text', incidentTypeItems);
		incidentTypeItems = _.unionBy(incidentTypeItems, 'text');

		return incidentTypeItems;
	}
};
